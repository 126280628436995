import React from 'react'
import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import '../../../static/styles/fontawesome-5.15.2/css/all.css'
import Collapsible from 'react-collapsible'

const MotieTable = (props) => {
  const { tabeldata } = props
  const intl = useIntl();
  var options = { year: 'numeric', month: 'short', day: 'numeric' }
  const rijen = tabeldata.map(function ({ node }) {
    const rij = node
    const datumIngediend = new Date(rij.datumIngediend).getTime()
    const datumMooi = new Intl.DateTimeFormat('nl-NL', options).format(
      datumIngediend
    )
    const type = rij.type
    const motietitel = rij.motietitel
    const indieners = Array.from(rij.indieners)
    const fractielid = rij.fractielid
    const portefeuilles = rij.portefeuilles
    const beschrijving = rij.beschrijving.beschrijving
    const resultaat = rij.resultaat
    const risLink = rij.risLink

    return {
      datumIngediend,
      datumMooi,
      type,
      motietitel,
      indieners,
      fractielid,
      portefeuilles,
      beschrijving,
      resultaat,
      risLink,
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: intl.formatMessage({id: "Datum"}),
        accessor: (row) => new Date(row.datumIngediend),
        sortType: 'datetime',
        sortDescFirst: true,
        disableFilters: true,
        id: 'datumIngediend',
        Cell: ({ row }) => <span>{row.original.datumMooi}</span>,
      },
      {
        Header: intl.formatMessage({id: "Type"}),
        accessor: (row) => intl.formatMessage({id: row.type}),
        sortType: 'basic',
        disableSortBy: true,
        Cell: (row) => (
          <div>
            {
              (() => {
                if (row.value === intl.formatMessage({id:'Amendement'}))
                  return <span title={row.value}>A</span>
                if (row.value === intl.formatMessage({id:'Initiatiefvoorstel'}))
                  return <span title={row.value}>I</span>
                if (row.value === intl.formatMessage({id:'Motie'}))
                  return <span title={row.value}>M</span>
                if (row.value === intl.formatMessage({id:'Schriftelijke Vragen'}))
                  return <span title={row.value}>{intl.formatMessage({id: "schriftenlijke_vragen_afkorting"})}</span>
                if (row.value === intl.formatMessage({id:'Toezegging'}))
                  return <span title={row.value}>{intl.formatMessage({id: "toezegging_afkorting"})}</span>
              })()
            }
          </div>
        ),
      },
      {
        Header: intl.formatMessage({id: "Titel"}),
        accessor: 'motietitel',
        Cell: ({ row }) => (
          <span>
            {row.original.risLink ?
            <a
              href={row.original.risLink}
              target="_blank"
              rel="noreferrer"
              key={row.id}
            >
              {row.original.motietitel}
            </a>
            : row.original.motietitel}
          </span>
        ),
        sortType: 'basic',
        disableFilters: true,
      },
      {
        Header: intl.formatMessage({id: "Partijen"}),
        accessor: (row) => `${row.indieners}`,
        disableSortBy: true,
        Filter: SelectColumnPartijFilter,
        filter: 'includes',
        Cell: ({ row }) => (
          <div>
            {row.original.indieners.map(function (partij, index) {
              return <span key={index}>{(index ? ', ' : '') + partij}</span>
            })}
          </div>
        ),
      },
      {
        Header: intl.formatMessage({id:'Fractielid'}),
        accessor: 'fractielid[0].name',
        sortType: 'basic',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div>
            {row.original.fractielid
              ? row.original.fractielid.map(function (lid, index) {
                const url = `/mensen/${lid.slug}/`
                return (
                  <span key={index}>
                    {' '}
                    {index ? ', ' : ''}
                    <Link
                      to={url}
                    >
                      {lid.name}
                    </Link>
                  </span>
                )
              })
              : ''}
          </div>
        ),
      },
      {
        Header: intl.formatMessage({id:'Portefeuille'}),
        accessor: (row) =>
          row.portefeuilles.map(function (porto, index) {
            return porto.titel
          }),
        sortType: 'basic',
        disableSortBy: true,
        Filter: SelectColumnPortefeuilleFilter,
        filter: 'includes',
        Cell: ({ row }) => (
          <div>
            {row.original.portefeuilles
              ? row.original.portefeuilles.map(function (porto, index) {
                const portefeuille = porto
                const url = `/portefeuilles/${portefeuille.slug}/`
                return (
                  <span key={index} itemProp="portefeuillenaam">
                    {index ? ', ' : ''}
                    <Link to={url}>{intl.formatMessage({id:portefeuille.titel})}</Link>
                  </span>
                )
              })
              : ''}
          </div>
        ),
      },
      {
        Header: intl.formatMessage({id:'Beschrijving'}),
        accessor: 'beschrijving',
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => (
          <div>
            {/* <span className="hide-for-small hide-for-medium">{row.value}</span> */}
            <Collapsible
              trigger={
                <i
                  className="fas fa-info-circle"
                  title={row.value}
                  style={{ fontSize: 15 + 'px' }}
                ></i>
              }
            >
              <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                <div style={{ margin: 0, wordBreak: 'break-word' }} dangerouslySetInnerHTML={{
                  __html: row.value,
                }}>
                </div>
              </div>
            </Collapsible>
          </div>
        ),
      },
      {
        Header: intl.formatMessage({id:'Uitslag'}),
        accessor: (row) => intl.formatMessage({id: row.resultaat}),
        disableSortBy: true,
        Cell: (row) => (
          <div>
            {/* <span className="hide-for-small hide-for-medium">{row.value}</span> */}
            {
              (() => {
                if (row.value === intl.formatMessage({id:'Aangenomen'}))
                  return <Collapsible
                      trigger={
                        <i className="fas fa-check" title={row.value} style={{ fontSize: 15 + 'px' }}></i>
                      }
                    >
                      <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                        {intl.formatMessage({id:"Aangenomen"})}
                      </div>
                    </Collapsible>
                if (row.value === intl.formatMessage({id:'Verworpen'}))
                  return <Collapsible
                      trigger={
                        <i
                          className="fas fa-times"
                          title={row.value}
                          style={{ fontSize: 15 + 'px' }}
                        ></i>
                      }
                    >
                      <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                        {intl.formatMessage({id:"Verworpen"})}
                      </div>
                    </Collapsible>
                if (row.value === intl.formatMessage({id:'Toegezegd'}))
                  return <Collapsible
                      trigger={
                        <i
                          className="fas fa-thumbs-up"
                          title={row.value}
                          style={{ fontSize: 15 + 'px' }}
                        ></i>
                      }
                    >
                      <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                        {intl.formatMessage({id:"Toegezegd"})}
                      </div>
                    </Collapsible>

                if (row.value === intl.formatMessage({id:'Ingetrokken'}))
                  return <Collapsible
                      trigger={
                        <i
                          className="fas fa-eject"
                          title={row.value}
                          style={{ fontSize: 15 + 'px' }}
                        ></i>
                      }
                    >
                      <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                        {intl.formatMessage({id:"Ingetrokken"})}
                      </div>
                    </Collapsible>

                if (row.value === intl.formatMessage({id:'Gesteld'}))
                  return <Collapsible
                      trigger={
                        <i
                          className="fas fa-feather-alt"
                          title={row.value}
                          style={{ fontSize: 15 + 'px' }}
                        ></i>
                      }
                    >
                      <div className="content-inner" style={{ fontSize: 12 + 'px' }}>
                        {intl.formatMessage({id:"Gesteld"})}
                      </div>
                    </Collapsible>
              })()
            }
          </div>
        ),
      },
    ],
    [intl]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: SelectColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    setAllFilters,
    // eslint-disable-next-line
    state: { sortBy, pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      // eslint-disable-next-line
      data: React.useMemo(() => rijen, []),
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: {
        sortBy: [
          {
            id: 'datumIngediend',
            desc: true,
          },
        ],
        pageIndex: 0,
        pageSize: 8,
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <div>
      <noscript>
        {intl.formatMessage({id:"Let op: Zonder Javascript werkt de motietabel niet volledig."})}
      </noscript>
      <div className="motieTableWrap">
        <table className="moties" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flexheader">
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span>
                            {' '}
                            <i
                              className="fas fa-angle-down"
                              style={{ color: 'white' }}>

                            </i>
                          </span>
                        ) : (
                            <span>
                              {' '}
                              <i
                                className="fas fa-angle-up"
                                style={{ color: 'white' }}>

                              </i>
                            </span>
                          )
                      ) : (
                          ''
                        )}

                      {column.canFilter ? (
                        <label className="wrap hide-for-small">
                          {' '}
                          {column.render('Filter')}{' '}
                        </label>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="motieTableWrap flexcontainer">
        {pageCount === 0 ? (
          <span className="pagination">
            <br />
            {intl.formatMessage({id:"Er is geen data gevonden met deze filters."})}{' '}
            {intl.formatMessage({id:"Klik"})}{' '}
            <button onClick={() => setAllFilters([])}>
                {intl.formatMessage({id:"hier"})}
            </button>{" "}
            {intl.formatMessage({id:"om de filters te resetten."})}
          </span>
        ) : (
            ''
          )}
        {pageCount > 1 ? (
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
            <span>
              {intl.formatMessage({id:"Pagina"})}{' '}
              <strong>
                {pageIndex + 1}{" "}{intl.formatMessage({id:"van"})}{" "}{pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | {intl.formatMessage({id: "Ga naar pagina:"})}{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '60px' }}
              />
            </span>
          </div>
        ) : (
            ''
          )}
      </div>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const intl = useIntl();
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const opt = new Set()
    preFilteredRows.forEach((row) => {
      opt.add(row.values[id])
    })
    return [...opt.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    // eslint-disable-next-line
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">{intl.formatMessage({id: "Alle"})}</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function SelectColumnPartijFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const intl = useIntl();
  // Calculate the options for filtering
  // using the preFilteredRows
  // eslint-disable-next-line
  const options = React.useMemo(() => {
    const opt = new Set()
    preFilteredRows.forEach((row) => {
      opt.add(row.values[id])
    })
    return [...opt.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    // eslint-disable-next-line
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">{intl.formatMessage({id: "Alle"})}</option>
      <option value="STIP">STIP</option>
      <option value="GL">GroenLinks</option>
      <option value="D66">D66</option>
      <option value="OD">Onafhankelijk Delft</option>
      <option value="VVD">VVD</option>
      <option value="CDA">CDA</option>
      <option value="SP">SP</option>
      <option value="PvdA">PvdA</option>
      <option value="CU">ChristenUnie</option>
      <option value="HvD">Hart voor Delft</option>
      <option value="SD">Stadsbelangen Delft</option>
      <option value="GS">Groep Stoelinga</option>
    </select>
  )
}

function SelectColumnPortefeuilleFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const intl = useIntl();
  // Calculate the options for filtering
  // using the preFilteredRows
  // eslint-disable-next-line
  const options = React.useMemo(() => {
    const opt = new Set()
    preFilteredRows.forEach((row) => {
      opt.add(row.values[id])
    })
    return [...opt.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    // eslint-disable-next-line
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">{intl.formatMessage({id: "Alle"})}</option>
      <option value="Bestuur & Dienstverlening">{intl.formatMessage({id:"Bestuur & Dienstverlening"})}</option>
      <option value="Cultuur">{intl.formatMessage({id:"Cultuur"})}</option>
      <option value="Economie">{intl.formatMessage({id:"Economie"})}</option>
      <option value="Integratie & Emancipatie">{intl.formatMessage({id:"Integratie & Emancipatie"})}</option>
      <option value="Jeugd">{intl.formatMessage({id:"Jeugd"})}</option>
      <option value="Klimaat & Energie">{intl.formatMessage({id:"Klimaat & Energie"})}</option>
      <option value="Mobiliteit">{intl.formatMessage({id:"Mobiliteit"})}</option>
      <option value="Ruimtelijke Ordening">{intl.formatMessage({id:"Ruimtelijke Ordening"})}</option>
      <option value="Sport">{intl.formatMessage({id:"Sport"})}</option>
      <option value="Veiligheid">{intl.formatMessage({id:"Veiligheid"})}</option>
      <option value="Werk & Inkomen">{intl.formatMessage({id:"Werk & Inkomen"})}</option>
      <option value="Wonen">{intl.formatMessage({id:"Wonen"})}</option>
      <option value="Zorg & Welzijn">{intl.formatMessage({id:"Zorg & Welzijn"})}</option>
    </select>
  )
}

export default MotieTable
