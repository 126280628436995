import React from 'react'
import { graphql } from 'gatsby'
import { Link, FormattedMessage, injectIntl } from '@intractive/gatsby-plugin-react-intl'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import Helmet from 'react-helmet'
import Layout from '../Layout'
import BlogArticleList from '../../components/BlogArticleList'
import MotieTable from '../MotieTable'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Portefeuille extends React.Component {
  render() {
    const artikelen = this.props.data.artikelen
    const portefeuille = this.props.data.contentfulPortefeuille
    const moties = this.props.data.moties
    const {
      titel,
      commissie,
      beschrijving,
      portefeuillehouders,
      header,
      blogartikel,
      motie__amendement_of_toezegging,
    } = portefeuille
    return (
      <Layout>
        <Helmet>
          <title>{titel} · STIP</title>
        </Helmet>
        <div className="main-content row">
          <div className="large-2 hide-for-medium columns sidebar hide-for-small">
            <Link to="/portefeuilles">&#8592; <FormattedMessage id="Portefeuilles"/></Link>
          </div>
          <div className="large-10 medium-12 portmain columns">
            <div className="large-9 medium-9 columns">
              <article className="portart">
                {header && (
                  <GatsbyImage image={header.gatsbyImageData} alt={header.title} className="portimg" />
                )}
                <h1 className="portefeuilletitel">Portefeuille {titel}</h1>
                <span className="commissienaam">Commissie {commissie}</span>
                {beschrijving && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: beschrijving.childMarkdownRemark.html,
                    }}
                  />
                )}
              </article>
            </div>
            <aside className="large-2 medium-3 columns align-center person person-sidebar">
              <strong>Portefeuille houders</strong>
              <ul className="large-block-grid-1 medium-block-grid-1 small-block-grid-3 person-listing">
                {portefeuillehouders &&
                  portefeuillehouders.map(function(person, index) {
                    return (
                      <li
                        className="portperson align-center"
                        itemScope
                        itemType="http://schema.org/Person"
                        key={person.slug}
                      >
                        <Link to={`/mensen/${person.slug}`}>
                          {person.image ? (
                            <div>
                              <GatsbyImage
                                image={person.image.gatsbyImageData}
                                className="round-image profielfoto"
                                itemProp="image"
                                alt={person.name} />
                            </div>
                          ) : (
                            <div>
                              <img
                                className="round-image profielfoto"
                                src="/images/placeholder.jpg"
                                alt="Placeholder"
                              />
                            </div>
                          )}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </aside>

            {motie__amendement_of_toezegging &&
              <div className="portefeuilleMoties columns">
                <h2>Moties, amendementen of toezeggingen</h2>
                <p>
                  <FormattedMessage id="Hieronder staat een overzicht van alle voorstellen die"/> de fractie <FormattedMessage id="heeft ingediend of gekregen" /> <FormattedMessage id="gerelateerd aan de portefeuille" /> {titel}.
                  <FormattedMessage id='Het is mogelijk om te sorteren en filters toe te passen. In de "Type" kolom kan je zien of het gaat om een motie (M), amendement (A), Toezegging (T), Initiatiefvoorstel (I) of Schriftelijke Vragen (SV).' />
                  <FormattedMessage id="Voor een overzicht van alle voorstellen van de fractie, zie" /> <Link to="/speerpunten/moties/"><FormattedMessage id="de motiepagina"/></Link>
                </p>
                <MotieTable tabeldata={moties.edges}></MotieTable>
              </div>
            }

            {blogartikel && 
              <div className="columns">
                <h2>Gerelateerde artikelen</h2>
                <div className="smallerArticleList">
                  <BlogArticleList articles={artikelen} />
                </div>
              </div>
            }
          </div>
        </div>
      </Layout>
    );
  }
}

Portefeuille.propTypes = propTypes

export default injectIntl(Portefeuille)

export const pageQuery = graphql`
  query($slug: String!, $node_locale: String) {
    contentfulPortefeuille(slug: { eq: $slug }, node_locale: {eq: $node_locale}) {
      titel
      commissie
      header {
        title
        gatsbyImageData(formats: WEBP, width: 800)
      }
      beschrijving {
        childMarkdownRemark {
          html
        }
      }
      blogartikel {
        id
      }
      motie__amendement_of_toezegging {
        id
      }
      portefeuillehouders {
        name
        slug
        jobtitle
        id
        image {
          gatsbyImageData(formats: WEBP, width: 200)
        }
      }
    }
    moties: allContentfulMotieAmendementOfToezegging(sort: {fields: datumIngediend, order: DESC}, filter: {portefeuilles: {elemMatch: {slug: {eq: $slug}}}, node_locale: {eq: $node_locale}}) {
      edges {
        node {
          datumIngediend
          type
          motietitel
          indieners
          fractielid {
            name
            slug
          }
          portefeuilles {
            titel
            slug
          }
          beschrijving {
            beschrijving
          }
          resultaat
          risLink
        }
      }
    }
    artikelen: allContentfulBlogartikel(
      sort: { fields: date, order: DESC }
      filter: {
        bijbehorendePortefeuilles: { elemMatch: { slug: { eq: $slug } } },
        node_locale: {eq: $node_locale}
      }
    ) {
      edges {
        node {
          id
          migrationId
          slug
          date(formatString: "DD - MM - YYYY")
          title
          image {
            title
            gatsbyImageData(formats: WEBP, width: 430)
          }
        }
      }
    }
  }
`
